<template>
   <div class="col-12 px-0 mb-1">
      <div class="card mb-0" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center cursor-pointer" @click="buscarComprovantes">
               <div class="w-max-content pe-12 d-none d-lg-block">
                  <img :src="produto.banner == null ? '' : produto.banner" class="rounded" alt="icon" @error="imageError" height="50" />
               </div>
               <div class="col-md text-center text-md-start">
                  <div class="row align-items-center">
                     <div class="col-12">
                        <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ produto.nome }}</h1>
                        <p class="font-10 mb-0 mb-lg-2 limitador-1 text-secondary">
                           <span><i class="far fa-hashtag font-9 me-1"></i>{{ produto.id }}</span>
                           <span class="ms-3">
                              <i class="far fa-tags font-9 me-1"></i>
                              <span>{{ produto.familia == null || String(produto.familia).trim().length == 0 ? 'Sem família' : produto.familia }}</span>
                           </span>
                        </p>
                     </div>
                     <div class="col d-none d-lg-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-barcode color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-xl-inline"> Código(s):</strong>
                           <span> {{ produto.codigos == null || String(produto.codigos).trim().length == 0 ? 'Sem código' : produto.codigos }}</span>
                        </p>
                     </div>
                     <div class="col-5 d-none d-xl-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-bookmark color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-xl-inline"> Marca:</strong>
                           <span> {{ produto.marca == null || String(produto.marca).trim().length == 0 ? 'Sem marca' : produto.marca }}</span>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="wpx-125 text-center mx-auto mx-lg-0 mt-2 mt-lg-0 font-15">
                  <span class="d-block lh-1">
                     {{ parseFloat(produto.quantidade).toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits: 3}) }}
                     <small class="font-11">{{ produto.unidadeMedida == null || String(produto.unidadeMedida).trim().length == 0 ? '-' : produto.unidadeMedida }}</small>
                  </span>
                  <small class="font-12"><i class="far fa-box color-theme font-10 me-1"></i> Quantidade</small>
               </div>
               <div class="wpx-125 text-center mx-auto mx-lg-0 mt-2 mt-lg-0 font-15">
                  <span class="d-block lh-1"><small>R$ </small>{{ parseFloat(produto.valor).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
                  <small class="font-12"><i class="far fa-money-bill color-theme font-10 me-1"></i> Valor</small>   
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Produto',
   props: ['produto', 'index'],
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      buscarComprovantes : function () {
         this.$emit('buscarComprovantes', {'idProduto': this.produto.id, 'nomeProduto': this.produto.nome, 'operador': this.produto.idOperador})
      }
   }
}

</script>